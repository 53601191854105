import styled from "styled-components";

export const Container = styled.div`
  text-align: center;
  font-family: "Dancing Script", cursive;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`;

export const AppHeader = styled.header`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
  font-size: calc(10px + 2vmin);
  color: black;
  h1 {
    margin: 0;
  }
  p {
    margin: 10px 0 20px 0;
  }
`;
