import React from "react";
import ReactPlayer from "react-player";
import { Container, AppHeader } from "./AppElements";

function App() {
  return (
    <Container>
      <img
        alt="banner"
        src="https://media-api.xogrp.com/images/9e906dc7-ab3f-4e14-bfe9-cad416cca43c~rt_auto-rs_1024.h?ordering=explicit"
      />
      <AppHeader>
        <h1>Emily and Sam</h1>
        <p>November 7th, 2020</p>

        <ReactPlayer
          url="https://stream.mux.com/RdvPE2eRWfVDSwne2WrnCSgyXhBh45yf.m3u8"
          playing
          controls
          width="88%"
          height="100%"
        />
      </AppHeader>
    </Container>
  );
}

export default App;
